































import {
  computed,
  defineComponent,
  PropType,
  toRefs
} from '@nuxtjs/composition-api'
import CButtonGroup from '~/components/shared/configurable/button/CButtonGroup.vue'
import { Option, Size } from '~/models/shared/types'
import { useI18n } from '~/compositions/i18n'

type ButtonGroupValueType = string | number

export default defineComponent({
  components: { CButtonGroup },
  props: {
    options: {
      type: Array as PropType<Option[]>,
      default() {
        return []
      }
    },
    value: {
      type: [Number, String],
      default: null
    },
    size: {
      type: String as PropType<Size>,
      default: Size.SMALL,
      required: false
    },
    buttonVariant: {
      type: String,
      default: 'secondary'
    },
    isQuickSearch: {
      type: Boolean,
      default: false
    },
    newStyle: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  } as any,
  setup(props, { emit }) {
    const { value, size, options } = toRefs(props)

    const { t } = useI18n()

    const selected = computed({
      get() {
        return value.value
      },
      set(newValue: ButtonGroupValueType) {
        emit('input', newValue)
        emit('change', newValue)
      }
    })

    const tabs = computed(() => {
      return [
        {
          id: 1,
          name: t('buy::quick_search'),
          isActive: selected.value === 'buy',
          activeClasses:
            '!tw-bg-blue-50 !tw-border-[#1976D2] !tw-text-[#1976D2]',
          borderClasses: '!tw-rounded-r-none'
        },
        {
          id: 2,
          name: 'Leasing',
          isActive: selected.value === 'leasing',
          activeClasses:
            '!tw-bg-blue-50 !tw-border-[#1976D2] !tw-text-[#1976D2]',
          borderClasses: '!tw-rounded-l-none'
        }
      ]
    })

    const handleTabSelection = (index: number) => {
      tabs.value.forEach(tab => (tab.isActive = false))
      tabs.value[index].isActive = true
      changeSelectedValue(options.value[index].value)
    }

    const changeSelectedValue = (value: ButtonGroupValueType) => {
      if (selected.value === value) {
        // don't refresh the value if it hasn't changed
        return
      }
      selected.value = value
    }

    const roundnessClass = computed(() => {
      switch (size.value) {
        case 'xs':
          return 'tw-rounded-md'
        case 'sm':
          return 'tw-rounded-lg'
        case 'lg':
          return 'tw-rounded-2xl'
        case 'md':
        default:
          return 'tw-rounded-xl'
      }
    })

    const isSelected = (val: any) => {
      return selected.value === val
    }

    return {
      changeSelectedValue,
      selected,
      tabs,
      roundnessClass,
      isSelected,
      handleTabSelection
    }
  }
})
